import {useEffect} from 'react'
import {TRACKING_LOADING_DELAY} from '../../constants'
import {useInteractionStudioLoader} from '../../interaction-studio-api'

/**
 * a hook to load interaction studio pixel
 * @param {boolean} loadImmediately - if true, load the pixel immediately, otherwise wait for the TRACKING_LOADING_DELAY
 */
const useInteractionStudioPixel = ({loadImmediately} = {}) => {
    const loadIS = useInteractionStudioLoader()

    // activate loading immediately or after a delay
    useEffect(() => {
        if (loadImmediately) {
            loadIS()
            return
        }

        const timer = setTimeout(() => {
            loadIS()
        }, TRACKING_LOADING_DELAY)
        return () => clearTimeout(timer)
    }, [loadImmediately, loadIS])
}

export default useInteractionStudioPixel
