// istanbul ignore file

import {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import useOcapiSite from '../../hooks/useOcapiSite'
import useMultiSite from '../../hooks/use-multi-site'
import {DATA_LAYER_INITIALIZATION_EVENT, trackEvent} from '../analytics'
import {useCurrentBasket} from '../../hooks/use-current-basket'
import usePageVisibility from '../../hooks/use-page-visibility'
import {INTERVAL_TO_EMIT_VIRTUAL_PAGE_EVENT} from '../../constants'
import useEffectEvent from '../../hooks/use-effect-event'
import {useCurrentCustomer} from '../../hooks/use-current-customer'
import {useConfig} from '@salesforce/commerce-sdk-react'

export const useInitialDataLayer = ({pageType}) => {
    const {allowedLocales} = useOcapiSite()
    const {pathname} = useLocation()
    const {locale, site} = useMultiSite()
    const {data: basket} = useCurrentBasket()
    const {currency: apiCurrency} = useConfig()
    const isPageVisible = usePageVisibility()
    // track page visibility and when it changed
    const [pageVisibilityState, setPageVisibilityState] = useState({
        timestamp: Date.now(),
        visible: isPageVisible
    })

    const {
        data: {
            customerId,
            isRegistered,
            c_ordersTotal: customerOrdersTotal,
            email: customerEmail
        } = {},
        isInitialLoading: isCustomerLoading
    } = useCurrentCustomer()

    const customerInfo = useMemo(() => {
        if (isCustomerLoading || !customerId) {
            return null
        }
        if (isRegistered) {
            return {
                user_type: 'customer',
                user_loggedIn: true,
                user_total_orders: customerOrdersTotal
            }
        } else {
            return {
                user_type: 'guest',
                user_loggedIn: false
            }
        }
    }, [customerId, isRegistered, customerOrdersTotal, isCustomerLoading])

    // force to trigger emitting the event
    const [triggerEvent, setTriggerEvent] = useState(0)

    // track page visibility and trigger the event if the page become visible after at least 30 minutes
    useEffect(() => {
        // if visibility didn't change to nothing
        if (pageVisibilityState.visible === isPageVisible) {
            return
        }

        // if page become visible and the last time it was visible was more than 30 minutes ago
        if (
            isPageVisible &&
            Date.now() - pageVisibilityState.timestamp >= INTERVAL_TO_EMIT_VIRTUAL_PAGE_EVENT
        ) {
            // trigger the event
            setTriggerEvent((i) => i + 1)
        }

        // update the state
        setPageVisibilityState({timestamp: Date.now(), visible: isPageVisible})
    }, [isPageVisible, pageVisibilityState])

    const localeInfo = useMemo(() => {
        return allowedLocales?.find((info) => info.id === (locale.id || locale))
    }, [locale, allowedLocales])

    // use useEffectEvent to create a function to append latest values to the event without triggering a new event
    const onTrackEvent = useEffectEvent((event) =>
        trackEvent({
            ...event,
            // include all properties, that should not trigger the tracking, but are needed as part of the event
            cart_value: basket?.orderTotal || 0,
            has_cookiebot: site?.gdpr?.enableCookiebot || false,
            has_ecommerce: !site?.isContentOnlySite
        })
    )
    const currency = basket?.currency || apiCurrency
    const email = customerEmail || basket?.customerInfo?.email
    // trigger the event when page changes or forced with triggerEvent
    useEffect(() => {
        // do not track if there is no locale info or the currency still undefined
        if (!localeInfo || !currency || !customerInfo) {
            return
        }

        onTrackEvent({
            event: DATA_LAYER_INITIALIZATION_EVENT,
            page_type: pageType,
            page_language: localeInfo.language,
            page_country2: localeInfo.country,
            page_country3: localeInfo.iso3Country,
            canonical_url_path: pathname,
            page_currency: currency,
            ecommerce: {
                currency: currency
            },
            ...(email && {email: email}),
            ...customerInfo
        })
    }, [pageType, pathname, localeInfo, currency, email, customerInfo, triggerEvent, onTrackEvent])
}
