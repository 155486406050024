import React from 'react'
import PropTypes from 'prop-types'
import {useInteractionStudioOverriddenContent} from '../../interaction-studio-api'

const InteractionStudioContent = ({children, id}) => {
    const overridenContent = useInteractionStudioOverriddenContent(id)

    if (overridenContent) {
        return <div dangerouslySetInnerHTML={{__html: overridenContent}} />
    }

    return children
}

InteractionStudioContent.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired
}

export default InteractionStudioContent
