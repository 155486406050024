import {useEffect, useState} from 'react'

const isSupported =
    typeof document !== 'undefined' && typeof document.visibilityState !== 'undefined'
const isPageVisible = () => !isSupported || document.visibilityState === 'visible'

/**
 * Returns true if the page is visible, false otherwise.
 * @returns {boolean}
 */
const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(isPageVisible())

    useEffect(() => {
        if (!isSupported) return
        const onVisibilityChange = () => setIsVisible(isPageVisible())
        document.addEventListener('visibilitychange', onVisibilityChange, {passive: true})
        return () => document.removeEventListener('visibilitychange', onVisibilityChange)
    }, [])

    return isVisible
}

export default usePageVisibility
