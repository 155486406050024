import React from 'react'
import CMSContent from '../cms-content'
import InteractionStudioContent from '../interaction-studio/content'
import {TYPE_CONTENT_SLOT} from '../cms-content/hooks/use-cms-content'

const HeaderContent = () => {
    return (
        <InteractionStudioContent id="is-header-content">
            <CMSContent id="header-content" type={TYPE_CONTENT_SLOT} showError={false} />
        </InteractionStudioContent>
    )
}

export default HeaderContent
